import React, { useState } from 'react'
import {
  Box,
  ColorGuide,
  Typography,
  ArrowBackwardIcon,
  Divider,
  ArrowForwardIcon,
  Pill,
} from 'components'
import { getAvailabilityForDate, useParams } from 'utils'
import ReactCalendar from 'react-calendar'
import { format, parse, isBefore, isPast, toDate } from 'date-fns'
import './calendar.css'

export default function FreelancerProfileWLCalendarSection({
  freelancer,
  onEdit,
}) {
  const lastUpdatedDate = freelancer.lastUpdated
    ? format(freelancer.lastUpdated.toDate(), 'MMMM do, yyyy')
    : null

  return (
    <Box
      maxWidth={420}
      boxSizing="border-box"
      border="1px solid #DDD"
      borderRadius={10}
      py={3}
      px={3.5}
    >
      <Box flexVariant="space-between align-center">
        <Typography variant="h5" style={{ fontWeight: '600' }}>
          Calendar
        </Typography>

        {onEdit && (
          <Pill size="small" onClick={onEdit}>
            Edit
          </Pill>
        )}
      </Box>
{/* Last updated may be worth implementing */}
      {/* {lastUpdatedDate && (
        <Typography variant="body2" style={{ marginTop: 4 }}>
          Last updated on {lastUpdatedDate}
        </Typography>
      )} */}

      <Divider />

      <SmallCalendar user={freelancer} />

      <Box maxWidth={400} mx="auto" my={3}>
        <ColorGuide />
      </Box>

    </Box>
  )
}

const SmallCalendar = ({ user, hideWeekends }) => {
  const { date: dateParam } = useParams()
  const startDate = parse(
    dateParam || format(new Date(), 'yyyy-MM-dd'),
    'yyyy-MM-dd',
    +new Date(),
  )
  const [activeDate] = useState(startDate)

  const getTileClassName = ({ date, view }) => {
    if (!user.availableFrom) {
        return availabilityClasses[0]
    }
    const status = isBefore(user.availableFrom.toDate(), date) ? 1 : 2
    return availabilityClasses[status]
  }

  const getTileContent = ({ date, view }) => {
    const status = isBefore(user.availableFrom.toDate(), Date.now()) ? 1 : 2
  }

  return (
    <ReactCalendar
      maxDetail="month"
      minDetail="month"
      className="mini-calendar"
      formatShortWeekday={(_, date) => days[date.getDay()]}
      nextLabel={<ArrowForwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />}
      prevLabel={
        <ArrowBackwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />
      }
      tileClassName={getTileClassName}
    //   tileContent={getTileContent}
      defaultActiveStartDate={startDate}
      activeDate={activeDate}
    />
  )
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const availabilityClasses = [
  'react-calendar__tile--unset',
  'react-calendar__tile--available',
  'react-calendar__tile--unavailable',
  'react-calendar__tile--maybe-available',
]

function AvailabilityMessageIndicator({ color }) {
  return (
    <Box
      bgcolor={color}
      style={{
        width: 4,
        height: 4,
        borderRadius: 20,
        position: 'absolute',
        bottom: 2,
      }}
    />
  )
}
