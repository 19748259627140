import { Box } from 'components'
import { FilterCheckbox } from './FilterCheckbox'

export const AvailableCommitmentsFilter = ({ filterState: state, setFilterState: setState, resetState }) => {

    const { fullTime, partTime, freelance } = state.available_commitments || {}
    const handleChange = (e) => {
        const { name, checked } = e.target
        setState((prevState) => {
            if (checked) {
                return { ...prevState, available_commitments: { ...prevState.available_commitments, [name]: !!checked } }
            } else {
                const { [name]: valueToRemove, ...updatedCommitments } = prevState.available_commitments
                return { ...prevState, available_commitments: updatedCommitments }
            }
        })
    }

    return (
        <Box display="flex" flex={1} flexDirection="column" minWidth={'max-content'} onChange={handleChange}>
            <FilterCheckbox
                label="Contract: Full time"
                checked={!!fullTime}
                // onChange={() => setState({ ...state, available_commitments: { ...state.available_commitments, fullTime: !fullTime } })}
                onReset={() => resetState('available_commitments')}
                name="fullTime"
            />
            <FilterCheckbox
                label="Contract: Part time"
                checked={!!partTime}
                // onChange={() => setState({ ...state, available_commitments: { ...state.available_commitments, partTime: !partTime } })}
                onReset={() => resetState('available_commitments')}
                name='partTime'
            />
            <FilterCheckbox
                label="Freelance"
                checked={!!freelance}
                // onChange={() => setState({ ...state, available_commitments: { ...state.available_commitments, freelance: !freelance } })}
                onReset={() => resetState('available_commitments')}
                name='freelance'
            />
        </Box>
    )
}