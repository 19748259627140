import React, { useEffect, useState } from 'react'

import { AvailabilityFilter } from './filters/AvailabilityFilter'
import { DateFilter } from './filters/DateFilter'
import { ExperienceFilter } from './filters/ExperienceFilter'
import { TagFilter } from './filters/TagFilter'
import { ExtrasFilter } from './filters/ExtrasFilter'
import { WorkStatusFilters } from './filters/WorkStatusFilters'
import { AvailableCommitmentsFilter } from './filters/AvailableCommitmentsFilter'
import { DiversityFilter } from './filters/DiversityFilter'
import { EmploymentStatusFilter } from './filters/EmploymentStatusFilter'

import {
  Box,
  VerticalDivider,
  Typography,
  ExperienceIcon,
  TagIcon,
  StarBorderIcon,
} from 'components'

import { useHasActiveSubscription, useIsMobile } from 'utils'
import pluralize from 'pluralize'
import { format } from 'date-fns'
import {
  FilterMenuButton,
  DesktopFilters,
  MobileFilters,
  MobileFilterMenu,
} from '.'
import { 
  AVAILABLE_FROM_CALENDAR, 
  GENDER_TAGS_ENABLED, 
  DIVERSITY_TAGS_ENABLED, 
  COMMITMENT_STATUS, 
  ONTARIO_WORKING_STATUS, 
  ONTARIO_RESIDENCE_STATUS,
  USE_DEMO_LANDER } from 'constants'

export const FilterBar = ({
  user,
  filterState,
  setFilterState,
  cities,
  titles,
  skills,
}) => {
  const isMobile = useIsMobile()
  const [tempFilterState, setTempFilterState] = useState({ ...filterState })

  const { hasActiveSubscription } = useHasActiveSubscription(user?.id)

  useEffect(() => {
    setTempFilterState(() => ({ ...filterState }))
  }, [filterState])

  let heading = 'Search Filters'

  if (filterState.role) {
    heading = `${pluralize(filterState.role)}`
    if (filterState.city) {
      heading = `${pluralize(filterState.role)} in ${cities[filterState.city]}`
    }
  } else if (filterState.city) {
    heading = `Freelancers in ${cities[filterState.city]}`
  }

  const resetState = (...keys) => {
    if (keys.length === 0) {
      keys = Object.keys(filterInitialState)
    }
    keys.forEach((key) => {
      setTempFilterState((fs) => ({ ...fs, [key]: filterInitialState[key] }))
      setFilterState((fs) => ({ ...fs, [key]: filterInitialState[key] }))
    })
  }

  return (
    <Box display="flex" alignItems="center" mt={3.5} mb={2}>
      <Typography variant="h5">{heading}</Typography>
      <VerticalDivider mx={3} />
      {isMobile ? (
        <MarketsMobileFilters
          tempFilterState={tempFilterState}
          setTempFilterState={setTempFilterState}
          resetState={resetState}
          titles={titles}
          skills={skills}
          cities={cities}
          setFilterState={setFilterState}
          hasActiveSubscription={hasActiveSubscription}
        />
      ) : (
        <MarketsDesktopFilters
          filterState={filterState}
          setFilterState={setFilterState}
          tempFilterState={tempFilterState}
          setTempFilterState={setTempFilterState}
          resetState={resetState}
          titles={titles}
          skills={skills}
          cities={cities}
          hasActiveSubscription={hasActiveSubscription}
        />
      )}
    </Box>
  )
}

const MarketsMobileFilters = ({
  tempFilterState,
  setTempFilterState,
  resetState,
  titles,
  skills,
  cities,
  setFilterState,
  hasActiveSubscription,
}) => {
  const [open, setOpen] = useState(false)
  const filterProps = {
    filterState: tempFilterState,
    setFilterState: setTempFilterState,
  }

  return (
    <MobileFilters
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
        setFilterState({ ...tempFilterState })
      }}
      onClear={() => {
        resetState()
        setOpen(false)
      }}
    >
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Dates">
        <DateFilter {...filterProps} />
      </MobileFilterMenu>
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Availability">
        <AvailabilityFilter {...filterProps} />
      </MobileFilterMenu>
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Years">
        <ExperienceFilter {...filterProps} />
      </MobileFilterMenu>
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Role">
        <TagFilter
          items={titles}
          onChange={(role) => setFilterState((s) => ({ ...s, role }))}
        />
      </MobileFilterMenu>
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Location">
        <TagFilter
          items={Object.values(cities)}
          onChange={(t) => {
            const city = Object.entries(cities).find(([k, v]) => v === t)[0]
            setFilterState((s) => ({ ...s, city }))
          }}
        />
      </MobileFilterMenu>
      <MobileFilterMenu disabled={!hasActiveSubscription} label="Skills">
        <TagFilter
          items={skills}
          onChange={(skill) =>
            setFilterState((s) => ({ ...s, skills: [...s.skills, skill] }))
          }
        />
      </MobileFilterMenu>
    </MobileFilters>
  )
}

const MarketsDesktopFilters = ({
  filterState,
  setFilterState,
  tempFilterState,
  setTempFilterState,
  resetState,
  cities,
  hasActiveSubscription,
}) => {
  const dateChanged =
    +filterInitialState.date1 !== +filterState.date1 && filterState.date1

  const availChanged = Object.entries(filterInitialState.status).some(
    ([k, v]) => filterState.status[k] !== v,
  )

  const experienceChanged = Object.entries(filterInitialState.experience).some(
    ([k, v]) => filterState.experience[k] !== v,
  )

  const workStatusChanged = filterState.work_status || filterState.resident || filterState.relocate
  const miscChanged = filterState.favorites || filterState.has_equipment
  const roleChanged = !!filterState.role
  const skillChanged = filterState.skills.length > 0
  const locationChanged = !!filterState.city
  const availableCommitmentsChanged = Object.keys(filterState.available_commitments).length < 3
  const residentChanged = !!filterState.ontario_resident
  const relocateChanged = !!filterState.relocate_status
  const diversityChanged = !!filterState.gender_tags.length > 0 || !!filterState.diversity_tags.length > 0
  const employmentStatusChanged = filterState.employment_status.length < 3

  // trialing this format
  const anyChanged =
    locationChanged ||
    skillChanged ||
    roleChanged ||
    experienceChanged ||
    availChanged ||
    dateChanged ||
    employmentStatusChanged ||
    (ONTARIO_WORKING_STATUS && workStatusChanged) ||
    (ONTARIO_RESIDENCE_STATUS && residentChanged) ||
    (ONTARIO_RESIDENCE_STATUS && relocateChanged) ||
    (COMMITMENT_STATUS && availableCommitmentsChanged) ||
    (DIVERSITY_TAGS_ENABLED && diversityChanged)

  // const anyChanged = !CUSTOM_STATIC_LANDER ?
  // locationChanged ||
  // skillChanged ||
  // roleChanged ||
  // experienceChanged ||
  // availChanged ||
  // dateChanged ||
  // WORK_STATUS && workStatusChanged ||
  //  :
  // locationChanged ||
  // skillChanged ||
  // roleChanged ||
  // experienceChanged ||
  // workStatusChanged ||
  // residentChanged ||
  // relocateChanged ||
  // availableCommitmentsChanged


  const menuProps = {
    onSave: () => setFilterState({ ...tempFilterState }),
    onCancel: () => setTempFilterState({ ...filterState }),
  }
  const filterProps = {
    filterState: tempFilterState,
    setFilterState: setTempFilterState,
  }

  return (
    <DesktopFilters allowReset={anyChanged} resetState={resetState}>
      <FilterMenuButton
        disabled={!hasActiveSubscription}
        icon="Dates"
        label={
          dateChanged
            ? filterState.date2
              ? `${format(filterState.date1, 'MMM d')} - ${format(
                filterState.date2,
                'MMM d',
              )}`
              : format(filterState.date1, 'MMM d')
            : 'Dates'
        }
        changed={dateChanged}
        onReset={() => resetState('date1', 'date2')}
        {...menuProps}
      >
        <DateFilter {...filterProps} />
      </FilterMenuButton>
      {!AVAILABLE_FROM_CALENDAR ? (
        <FilterMenuButton
          disabled={!hasActiveSubscription}
          icon={availChanged ? null : 'Availability'}
          customIcon={
            availChanged ? <AvailIcon status={filterState.status} /> : null
          }
          label="Availability"
          changed={availChanged}
          onReset={() => resetState('status')}
          {...menuProps}
        >
          <AvailabilityFilter {...filterProps} />
        </FilterMenuButton>
      ) :
        <FilterMenuButton
          disabled={!hasActiveSubscription}
          icon={availChanged ? null : 'Availability'}
          customIcon={
            availChanged ? <AvailIcon status={filterState.status} /> : null
          }
          label="Availability"
          changed={availableCommitmentsChanged}
          onReset={() => resetState('available_commitments')}
          {...menuProps}
        >
          <AvailableCommitmentsFilter {...filterProps} />
        </FilterMenuButton>
      }

      <FilterMenuButton
        disabled={!hasActiveSubscription}
        icon="Years"
        label={
          experienceChanged
            ? `${filterState.experience[0]}-${filterState.experience[1]} Years`
            : 'Years'
        }
        Icon={ExperienceIcon}
        changed={experienceChanged}
        onReset={() => resetState('experience')}
        {...menuProps}
      >
        <ExperienceFilter {...filterProps} />
      </FilterMenuButton>
      <FilterMenuButton
        disabled={!hasActiveSubscription}
        label={'Extras'}
        Icon={StarBorderIcon}
        changed={miscChanged}
        onReset={() => {
          resetState('favorites')
          resetState('has_equipment')
        }}
        {...menuProps}
      >
        <ExtrasFilter {...filterProps} />
      </FilterMenuButton>
      {USE_DEMO_LANDER && (
              <FilterMenuButton
              disabled={!hasActiveSubscription}
              label="Status"
              changed={employmentStatusChanged}
              onReset={() => resetState('employment_status')}
              {...menuProps}
            >
              <EmploymentStatusFilter {...filterProps} />
            </FilterMenuButton>
      )}
      {ONTARIO_WORKING_STATUS && (
        <FilterMenuButton
          label={'Work Status'}
          changed={workStatusChanged || residentChanged || relocateChanged}
          onReset={() => {
            resetState('work_status')
            resetState('ontario_resident')
            resetState('relocate')
          }}
          {...menuProps}
        >
          <WorkStatusFilters {...filterProps} />
        </FilterMenuButton>
      )}
      {(GENDER_TAGS_ENABLED || DIVERSITY_TAGS_ENABLED) && (
        <FilterMenuButton
          label={`${GENDER_TAGS_ENABLED && 'Gender'} ${GENDER_TAGS_ENABLED && DIVERSITY_TAGS_ENABLED && ' + '} ${DIVERSITY_TAGS_ENABLED && 'Diversity'}`}
          changed={diversityChanged}
          onReset={() => resetState('gender_tags', 'diversity_tags')}
          {...menuProps}
        >
          <DiversityFilter {...filterProps} />
        </FilterMenuButton>
      )}
      {filterState.role && (
        <FilterMenuButton
          disabled={!hasActiveSubscription}
          label={filterState.role}
          Icon={TagIcon}
          changed={roleChanged}
          onReset={() => resetState('role')}
        />
      )}
      {filterState.city && (
        <FilterMenuButton
          disabled={!hasActiveSubscription}
          icon="location"
          label={cities[filterState.city]}
          changed={locationChanged}
          {...menuProps}
          onReset={() => resetState('city')}
          Icon={TagIcon}
        />
      )}
      {filterState.skills.map((skill) => (
        <FilterMenuButton
          disabled={!hasActiveSubscription}
          icon="skills"
          key={`skill-${skill}`}
          label={skill}
          changed={skillChanged}
          onReset={() => resetState('skills')}
        />
      ))}
    </DesktopFilters>
  )
}

export const filterInitialState = {
  date1: undefined,
  date2: undefined,
  experience: [1, 20],
  role: undefined,
  skills: [],
  city: undefined,
  favorites: undefined,
  has_equipment: undefined,
  ontario_resident: undefined,
  relocate: undefined,
  status: [1, 1, 1],
  permit_status: undefined,
  availability_status: undefined,
  available_commitments: { fullTime: true, partTime: true, freelance: true },
  gender_tags: [],
  diversity_tags: [],
  employment_status: ['Full Time', 'Contract', 'Freelance'],
}

const AvailIcon = ({ status }) => (
  <div style={{ marginRight: 10, marginBottom: -3 }}>
    {status.map(
      (status, i) =>
        status === 1 && (
          <div
            key={`status-${i}`}
            style={{
              height: 8,
              width: 8,
              borderRadius: 4,
              background: AVAIL_COLORS[i],
              marginRight: 2,
              marginBottom: 3,
            }}
          />
        ),
    )}
  </div>
)
const AVAIL_COLORS = ['#49b592', '#e8d53b', '#f06450']
