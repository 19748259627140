import React from 'react'
import { Box, Typography, Pill, Switch } from 'components'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { format, isAfter, isBefore } from 'date-fns'
import firebase from 'utils/firebase'

export default function FreelancerCommitmentSection({ freelancer, onEdit }) {
  const { desiredCommitments } = freelancer.desiredCommitments !== undefined ? freelancer : { desiredCommitments: {} }

  const setUser = (data) =>
    firebase
      .firestore()
      .collection('users')
      .doc(freelancer.id)
      .set(data, { merge: true })

  return (
    <Box
      flex={1}
      mt={1}
      pt={3.5}
      px={3.5}
      maxWidth={420}
      border="1px solid #DDD"
      borderRadius={10}
      style={{ backgroundColor: "white" }}
    >
      {onEdit && (
        <Box 
        display="flex" 
        alignItems="center"
        padding={1}
        mb={2.5} 
        flexVariant="row space-between" 
        style={freelancer.hidden ? { backgroundColor: 'red' } : { backgroundColor: 'green' }}
        >
          <Typography color='secondary'>Profile is {!freelancer.hidden ? 'Visible' : 'Hidden'}</Typography>
          <Switch variant="small" checked={!freelancer.hidden} onChange={e => setUser({ hidden: !e.target.checked })} />
        </Box>
      )}
      <Box flex={1} flexVariant="row space-between" mb={2.5}>
        <Typography variant="h5" style={{ fontWeight: '600' }}>
          Available for
        </Typography>

        {onEdit && (
          <Pill size="small" onClick={onEdit}>
            Edit
          </Pill>
        )}
      </Box>
      <List>

        <ListItem>
          <List>
            <ListItemText>
              <Typography variant="subtitle2" style={{ color: 'green' }}>{!!desiredCommitments.fullTime ? 'Contract: Full Time' : null}</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" style={{ color: 'green' }}>{!!desiredCommitments.partTime ? 'Contract: Part Time' : null}</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" style={{ color: 'green' }}>{!!desiredCommitments.freelance ? 'Freelance (IC)' : null}</Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="subtitle2" style={{ color: 'green' }}>
                {freelancer.availableFrom && isAfter(freelancer.availableFrom.toDate(), new Date()) ? `Start date: ${format(freelancer.availableFrom.toDate(), 'MMM dd, yyyy')}` : null}
                {freelancer.availableFrom && isBefore(freelancer.availableFrom.toDate(), new Date()) ? `Available Now` : null}
              </Typography>
            </ListItemText>
          </List>
        </ListItem>
      </List>
    </Box>
  )
}
