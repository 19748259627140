import React from 'react'
import { Box, Typography, Pill, IconButton, LinkIcon, Divider } from 'components'
import { List, ListItem, ListItemText, Link, ListItemHeading } from '@material-ui/core'
import { WORK_STATUSES, RELOCATION_STATUSES } from 'constants'
import { format } from 'date-fns'

export default function FreelancerWorkStatusSection({ freelancer, onEdit }) {
  const { permitExpiry, ontarioWorkingStatus, relocationStatus, resident } = freelancer
  return (
    <Box
      flex={1}
      mt={1}
      pt={3.5}
      px={3.5}
      maxWidth={420}
      border="1px solid #DDD"
      borderRadius={10}
      style={{ backgroundColor: "white" }}
    >
      <Box flex={1} flexVariant="row space-between" mb={2.5}>
        <Typography variant="h5" style={{ fontWeight: '600' }}>
          Employment Status
        </Typography>

        {onEdit && (
          <Pill size="small" onClick={onEdit}>
            Edit
          </Pill>
        )}
      </Box>
      <List>
        <ListItem>
          {/* <ListItemText
            secondary={(ontarioWorkingStatus === 2 || ontarioWorkingStatus === 1) && permitExpiry ? `Expires ${format(permitExpiry.toDate(), 'MMM dd, yyyy')}` : null}
          >
            {WORK_STATUSES[ontarioWorkingStatus]}
          </ListItemText> */}
          <ListItemText>
            {ontarioWorkingStatus > 0 ? 'Eligible to work in Canada' : 'Not eligible to work in Canada'}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
          secondary={RELOCATION_STATUSES[relocationStatus]}>
            {resident ? 'Ontario Resident' : 'Not an Ontario Resident'}
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  )
}
