import { Box } from 'components'
import { FilterCheckbox } from './FilterCheckbox'

export const WorkStatusFilters = ({ filterState: state, setFilterState: setState, resetState }) => {
    const { ontario_resident, relocate, work_status } = state

    return (
        <Box display="flex" flex={1} flexDirection="column" minWidth={160}>
            <FilterCheckbox
                label="Ontario Resident"
                checked={!!ontario_resident}
                onChange={() => setState({ ...state, ontario_resident: !ontario_resident })}
                onReset={() => resetState('ontario_resident')}
            />
            <FilterCheckbox
                label="May relocate"
                checked={!!relocate}
                onChange={() => setState({ ...state, relocate: !relocate })}
                onReset={() => resetState('relocate')}
            />
            <FilterCheckbox
                label="Legal to Work"
                checked={!!work_status}
                onChange={() => setState({ ...state, work_status: !work_status })}
                onReset={() => resetState('work_status')}
            />
        </Box>
    )
}