import { useState } from 'react'
import { Frame, Box, Heading, Typography, Button } from 'components'
import { ProgressBarFooter } from '../FreelancerRoles/FreelancerAddRole'
import { updateUser } from 'utils'
import { NUM_ONBOARDING_STEPS } from 'constants'
import firebase from 'utils/firebase'

const FreelancerUploadCv = ({ user, state, onNext}) => {
    const ref = firebase.storage().ref(`/cvs/${user.id}`)
    const [cvDisplay, setCvDisplay] = useState("")

    const onSubmit = () => {
        onNext()
    }
    const setUser = (data) =>
        firebase
            .firestore()
            .collection('users')
            .doc(user.id)
            .set(data, { merge: true })

    const onDelete = () => {
        const shouldDelete = window.confirm(
            'Are you sure you want to remove your CV?',
        )
        if (!shouldDelete) return
        ref.delete().then(() => setUser({ cvUrl: null }))
    }

    const onUpload = (e) => {
        setCvDisplay(e.target.files[0].name)
        ref
            .put(e.target.files[0])
            .then((a) => a.ref.getDownloadURL().then((cvUrl) => setUser({ cvUrl })))
    }
    return (
        <Frame maxWidth="xs" user={user}>
            <Box mx="auto" maxWidth={430}>
                <Heading subheading="This can also be done later. Must be in pdf format.">Upload your CV</Heading>
                <Box>
                    {user.cvUrl && (
                        <Typography>{cvDisplay}</Typography>
                    )}
                    {!user.cvUrl && (
                        <input
                            type="file"
                            accept="application/pdf"
                            id="file"
                            style={{ display: 'none' }}
                            onChange={onUpload}
                        />
                    )}
                    <Button
                        component="label"
                        for="file"
                        size="large"
                        onClick={user.cvUrl ? onDelete : () => { }}
                        type="submit"
                    >
                        {user.cvUrl ? 'Remove CV' : 'Upload CV'}
                    </Button>
                </Box>

            </Box>

            <ProgressBarFooter
                valid={true}
                stepIndex={8}
                firstStep={8}
                numSteps={NUM_ONBOARDING_STEPS}
                onNext={onSubmit}
                onPrev={() => { }}
            />
        </Frame>
    )
}

export default FreelancerUploadCv